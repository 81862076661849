<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.ronda"
        @click="clickCrearRonda"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { RONDA } from '@/utils/consts'
import { encodeBase64 } from '@/utils/router'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './MrondaViewData'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'descripcion',
        'tmronda_descripcion',
        { name: 'duracion_minutos', label: 'Duración estimada (minutos)' },
        'cliente_nombre',
        'puesto_servicio_descripcion',
        'servicio_descripcion',
      ],
      showingDialogs: {
        moreInfo: false,
      },
      rondaEnCurso: null,
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcliente
        item.title = item.cliente_nombre
        item.subtitle = [
          item.descripcion,
          item.puesto_servicio_descripcion,
          item.servicio_descripcion
        ]
        item.alerts = []
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.descripcion
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectMronda(this, this.routeParams.idmronda)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(this, this.routeParams.idmronda)
      const detailPuntos = this.addDetail(
        'puntos', 'Puntos de control', 'Puntos de control de paso programados', 'puntoControl'
      )
      detailPuntos.badge = resp.data.selectMrondaPunto.result.dataset[0].count || 0
      this.rondaEnCurso = resp.data.selectRondaEnCurso.result.dataset
    },
    clickDetail (data) {
      if (data.detail.name === 'puntos') {
        this.$appRouter.push({
          name: 'rondas__mronda-punto-list',
          query: {
            idsistema: this.routeParams.idmronda,
          },
        })
      }
    },
    async clickCrearRonda () {
      let crearNuevaRonda = true
      if (this.rondaEnCurso.length > 0 ) {
        crearNuevaRonda = await this.$alert.showConfirm(
          `Actualmente existen rondas en curso del tipo "${this.item.dataset.descripcion}". ¿Deseas iniciar otra?`,
          'Confirmación',
          { false: 'Continuar ronda', true: { text: 'Iniciar nueva', color: 'primary', flat: false } }
        )
      } else {
        crearNuevaRonda = await this.$alert.showConfirm(
          `¿Deseas iniciar una ronda del tipo "${this.item.dataset.descripcion}"?`
        )
      }
      if (crearNuevaRonda) {
        const ronda = await Data.crearRonda(this, this.routeParams.idmronda)
        this.$appRouter.push({
          name: 'rondas__ronda-realizar',
          params: {
            idronda: ronda.idronda,
          },
        })
      } else if (this.rondaEnCurso.length > 0) {
        if (this.rondaEnCurso.length === 1) {
          this.verRondaEnCurso(this.rondaEnCurso[0].idronda)
        } else {
          this.$appRouter.push(
            {
              name: 'rondas__ronda-list',
              query: {
                _filter: encodeBase64({
                  estado: {
                    value: RONDA.estados.enCurso,
                  },
                }),
              },
            }
          )
        }
      }
    },
    verRondaEnCurso (idronda) {
      this.$appRouter.push({
        name: 'rondas__ronda-realizar',
        params: {
          idronda: idronda,
        },
      })
    },
  },
}
</script>
