import { APIFilter } from '@/utils/api'
import { RONDA } from '@/utils/consts'

export default {
  async selectMronda (Vue, idmronda) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idmronda', idmronda)
    const resp = await Vue.$api.call('mronda.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails (Vue, idmronda) {
    let apiCalls = []
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idmronda', idmronda)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectMrondaPunto', method: 'mrondaPunto.select', params: { filter: apiFilter, wrapper: 'count' } })
    const apiFilterRonda = new APIFilter()
    apiFilterRonda
      .addExact('idmronda', idmronda)
      .addExact('estado', RONDA.estados.enCurso)
    apiCalls.push({ name: 'selectRondaEnCurso', method: 'ronda.select', params: { filter: apiFilterRonda } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async crearRonda (Vue, idmronda) {
    const ronda = await Vue.$api.call(
      'ronda.insert', { values: { idmronda } }
    )
    return ronda.data.result.dataset[0]
  },
}
